(function ($){


	$(document).ready(function (){

		$selector = {};

		$('a.language-selector-trigger').on('click',function (e){

			$selector = $('#language-selector');
			console.log ('go',$selector.find('ul'));
			e.preventDefault();
			e.stopPropagation();
			$selector.find('ul').css({

				'display':'block'
			});

			$(document.body).one('click',function (){

				console.log ('go for one on body');
				$selector.find('ul').css({

					'display':'none'
				});

			});
		});

	});

})(jQuery);
